import { useEffect, useMemo, useRef } from 'react';
import { useLocalizationContext } from '@sharesight/gatsby-plugin-sharesight-localization';

import { useScript } from 'hooks/useScript';
import { useContentfulLocale } from 'hooks/useContentfulLocale';

import { Container } from './Container.styled';

import type { FC, ReactNode } from 'react';
import type { Color, Size } from 'styled/types';

declare global {
  interface Window {
    Trustpilot: any;
  }
}

interface TrustPilotProps {
  tagId?: string | string[];
  background?: Color;
  padding?: Size;
  localizeTag?: boolean;
  header?: ReactNode;
  height?: number;
}

export const TrustPilot: FC<TrustPilotProps> = ({
  tagId = 'general',
  background,
  padding,
  localizeTag = true,
  header,
  height = 410,
}) => {
  const { currentLocale, defaultLocale } = useLocalizationContext();
  const {
    translations: { freeUsers },
  } = useContentfulLocale();
  useScript('https://widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js');

  // Create a reference to the <div> element which will represent the TrustBox
  const targetRef = useRef(null);
  useEffect(() => {
    if (typeof window !== undefined) window.Trustpilot?.loadFromElement(targetRef.current, true);
  }, [targetRef]);

  const tags = useMemo(
    () =>
      (Array.isArray(tagId) ? tagId : [tagId]).map(id =>
        !localizeTag ? `widget-${id}` : `widget-${id}-${currentLocale.id}`
      ),
    [tagId, currentLocale.id, localizeTag]
  );

  let trustpilotDomain = 'www';
  if (currentLocale !== defaultLocale && currentLocale.id !== 'global') {
    trustpilotDomain = currentLocale.id;
  }

  return (
    <Container padding={padding} background={background} id="trustpilot-reviews">
      {header || (
        <>
          <h2>Don't just take our word for it</h2>
          <p>
            Over {freeUsers} investors track their investments with Sharesight. Here’s what a few of
            them have to say:
          </p>
        </>
      )}

      <div
        ref={targetRef} // We need a reference to this element to load the TrustBox in the effect.
        className="trustpilot-widget"
        data-locale={currentLocale.lang}
        data-template-id="539adbd6dec7e10e686debee"
        data-businessunit-id="5e4b8f349f19c6000198ff88"
        data-style-height={`${height}px`}
        data-style-width="100%"
        data-theme="light"
        data-tags={tags.join(',')}
        data-stars="4,5"
        data-review-languages={[defaultLocale.lang, currentLocale.lang]}
        data-chromatic="ignore" // Do not consider this difference in Chromatic as it changes randomly; see: https://www.chromatic.com/docs/ignoring-elements
      >
        {/* Backup: until TrustPilot initializes: */}
        <a
          href={`https://${trustpilotDomain}.trustpilot.com/review/www.sharesight.com`}
          target="_blank"
          rel="noopener noreferrer"
        >
          See what our customers are saying on Trustpilot
        </a>
      </div>
    </Container>
  );
};
