const SVGBrokers = () => (
  <svg
    width="348"
    height="226"
    viewBox="0 0 348 226"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ width: '100%' }}
    role="img"
  >
    <title>Blue and purple shapes</title>
    <rect
      x="263.051"
      y="41.0127"
      width="17.9254"
      height="165.382"
      transform="rotate(90 263.051 41.0127)"
      fill="url(#paint0_linear_1532_13614)"
    />
    <rect
      x="263.051"
      y="101.978"
      width="17.9254"
      height="165.382"
      transform="rotate(90 263.051 101.978)"
      fill="url(#paint1_linear_1532_13614)"
    />
    <rect
      x="263.051"
      y="71.4958"
      width="17.9254"
      height="165.382"
      transform="rotate(90 263.051 71.4958)"
      fill="url(#paint2_linear_1532_13614)"
    />
    <path
      d="M209.488 185.228L309.882 185.229"
      stroke="url(#paint3_linear_1532_13614)"
      strokeWidth="7.8699"
      strokeLinecap="square"
    />
    <circle cx="97.6687" cy="153.124" r="67.7" fill="url(#paint4_linear_1532_13614)" />
    <circle cx="282.463" cy="157.588" r="17.2613" fill="url(#paint5_linear_1532_13614)" />
    <defs>
      <linearGradient
        id="paint0_linear_1532_13614"
        x1="272.013"
        y1="41.0127"
        x2="272.013"
        y2="214.458"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#5361DF" />
        <stop offset="0.859375" stopColor="#A9B0EF" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_1532_13614"
        x1="272.013"
        y1="101.978"
        x2="272.013"
        y2="275.423"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#5361DF" />
        <stop offset="0.859375" stopColor="#A9B0EF" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_1532_13614"
        x1="272.013"
        y1="71.4958"
        x2="272.013"
        y2="244.941"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#5361DF" />
        <stop offset="0.859375" stopColor="#A9B0EF" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_1532_13614"
        x1="308.459"
        y1="195.798"
        x2="309.311"
        y2="185.814"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#808BF3" />
        <stop offset="1" stopColor="#A3ABFB" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_1532_13614"
        x1="45.2015"
        y1="228.952"
        x2="125.419"
        y2="130.325"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#3E4DDB" />
        <stop offset="1" stopColor="#A3ABFB" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_1532_13614"
        x1="272.247"
        y1="174.849"
        x2="295.099"
        y2="166.575"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#6974E3" />
        <stop offset="1" stopColor="#A3ABFB" />
      </linearGradient>
    </defs>
  </svg>
);

// eslint-disable-next-line import/no-default-export
export default SVGBrokers;
