const SVGSecurity = () => (
  <svg
    width="426"
    height="427"
    viewBox="0 0 426 427"
    fill="none"
    style={{ width: '100%' }}
    xmlns="http://www.w3.org/2000/svg"
    role="img"
  >
    <title>Graphic illustration of security icon</title>
    <circle
      opacity="0.2"
      cx="213.042"
      cy="213.579"
      r="212.934"
      fill="url(#paint0_linear_1532_13715)"
    />
    <circle
      opacity="0.5"
      cx="213.042"
      cy="213.579"
      r="167.32"
      fill="url(#paint1_linear_1532_13715)"
    />
    <circle cx="213.042" cy="213.579" r="118.011" fill="url(#paint2_linear_1532_13715)" />
    <circle cx="213.041" cy="213.579" r="90.9687" fill="url(#paint3_linear_1532_13715)" />
    <circle cx="213.044" cy="213.579" r="65.1901" fill="#202DAC" />
    <g filter="url(#filter0_i_1532_13715)">
      <path
        d="M214.91 233.076H211.936C210.944 225.021 208.776 213.93 205.43 199.803C202.208 185.677 199.977 175.577 198.738 169.505C198.738 166.035 200.101 162.628 202.828 159.282C205.554 155.936 209.085 154.263 213.423 154.263C217.76 154.263 221.292 155.936 224.018 159.282C226.744 162.628 228.107 166.035 228.107 169.505C226.992 175.577 224.761 185.677 221.415 199.803C218.07 213.93 215.901 225.021 214.91 233.076ZM199.482 256.682C199.482 252.841 200.845 249.557 203.571 246.831C206.297 244.105 209.581 242.741 213.423 242.741C217.264 242.741 220.548 244.105 223.274 246.831C226 249.557 227.364 252.841 227.364 256.682C227.364 260.524 226 263.808 223.274 266.534C220.548 269.26 217.264 270.623 213.423 270.623C209.581 270.623 206.297 269.26 203.571 266.534C200.845 263.808 199.482 260.524 199.482 256.682Z"
        fill="white"
      />
    </g>
    <defs>
      <filter
        id="filter0_i_1532_13715"
        x="198.738"
        y="154.263"
        width="36.9783"
        height="123.969"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="7.6092" dy="7.6092" />
        <feGaussianBlur stdDeviation="6.76374" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.819608 0 0 0 0 0.839216 0 0 0 0 0.988235 0 0 0 1 0"
        />
        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_1532_13715" />
      </filter>
      <linearGradient
        id="paint0_linear_1532_13715"
        x1="95.0221"
        y1="389.565"
        x2="349.116"
        y2="181.731"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#A9B0EB" />
        <stop offset="1" stopColor="#D9DDFF" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_1532_13715"
        x1="120.305"
        y1="351.866"
        x2="319.967"
        y2="188.554"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#A9B0EB" />
        <stop offset="1" stopColor="#D9DDFF" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_1532_13715"
        x1="366.24"
        y1="231.275"
        x2="79.5451"
        y2="224.213"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#808BF3" stopOpacity="0.36" />
        <stop offset="0.567708" stopColor="#808BF3" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_1532_13715"
        x1="213.041"
        y1="122.611"
        x2="213.041"
        y2="304.548"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#202EAE" />
        <stop offset="0.911458" stopColor="#C2C9FF" />
      </linearGradient>
    </defs>
  </svg>
);

// eslint-disable-next-line import/no-default-export
export default SVGSecurity;
