const SVGMarkets = () => (
  <svg
    width="348"
    height="226"
    viewBox="0 0 348 226"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ width: '100%' }}
    role="img"
  >
    <title>A group of squares with different colours</title>
    <rect
      x="315.643"
      y="140.285"
      width="28.465"
      height="28.3003"
      transform="rotate(180 315.643 140.285)"
      fill="#FEBE9A"
    />
    <rect x="59.4551" y="83.6846" width="28.465" height="28.3003" fill="#FF9356" />
    <rect
      x="287.158"
      y="196.817"
      width="28.465"
      height="28.3003"
      transform="rotate(180 287.158 196.817)"
      fill="#FEBE9A"
    />
    <rect
      x="144.83"
      y="225.117"
      width="28.465"
      height="28.3003"
      transform="rotate(180 144.83 225.117)"
      fill="#FEBE9A"
    />
    <rect
      x="116.365"
      y="83.6162"
      width="28.465"
      height="28.3003"
      transform="rotate(180 116.365 83.6162)"
      fill="#FEBE9A"
    />
    <rect
      x="230.229"
      y="140.217"
      width="28.465"
      height="28.3003"
      transform="rotate(180 230.229 140.217)"
      fill="#FEBE9A"
    />
    <rect
      x="87.9004"
      y="168.517"
      width="28.465"
      height="28.3003"
      transform="rotate(180 87.9004 168.517)"
      fill="#FEBE9A"
    />
    <rect
      x="173.297"
      y="168.517"
      width="28.465"
      height="28.3003"
      transform="rotate(180 173.297 168.517)"
      fill="#FEBE9A"
    />
    <rect x="230.229" y="140.217" width="28.465" height="28.3003" fill="#FEBE9A" />
    <rect x="87.9004" y="168.517" width="28.465" height="28.3003" fill="#FEBE9A" />
    <rect x="173.297" y="83.6162" width="28.465" height="28.3003" fill="#FEBE9A" />
    <rect x="30.9688" y="111.917" width="28.465" height="28.3003" fill="#FEBE9A" />
    <rect
      x="287.158"
      y="140.217"
      width="28.465"
      height="28.3003"
      transform="rotate(180 287.158 140.217)"
      fill="#FF9356"
    />
    <rect
      x="144.83"
      y="168.517"
      width="28.465"
      height="28.3003"
      transform="rotate(180 144.83 168.517)"
      fill="#FF9356"
    />
    <rect
      x="173.297"
      y="196.817"
      width="28.465"
      height="28.3003"
      transform="rotate(180 173.297 196.817)"
      fill="#FEBE9A"
    />
    <rect x="230.229" y="168.517" width="28.465" height="28.3003" fill="#FF9356" />
    <rect x="87.9004" y="196.817" width="28.465" height="28.3003" fill="#FF9356" />
    <rect x="173.297" y="111.917" width="28.465" height="28.3003" fill="#FF9356" />
    <rect
      x="287.158"
      y="168.517"
      width="28.465"
      height="28.3003"
      transform="rotate(180 287.158 168.517)"
      fill="#FFEFE6"
    />
    <rect
      x="144.83"
      y="196.817"
      width="28.465"
      height="28.3003"
      transform="rotate(180 144.83 196.817)"
      fill="#FFEFE6"
    />
    <rect
      x="230.229"
      y="111.916"
      width="28.465"
      height="28.3003"
      transform="rotate(180 230.229 111.916)"
      fill="#FFEFE6"
    />
    <rect
      x="87.9004"
      y="140.217"
      width="28.465"
      height="28.3003"
      transform="rotate(180 87.9004 140.217)"
      fill="#FFEFE6"
    />
    <rect
      x="315.623"
      y="111.916"
      width="28.465"
      height="28.3003"
      transform="rotate(180 315.623 111.916)"
      fill="#FFEFE6"
    />
    <rect
      x="173.297"
      y="140.217"
      width="28.465"
      height="28.3003"
      transform="rotate(180 173.297 140.217)"
      fill="#FFEFE6"
    />
    <rect x="230.229" y="111.916" width="28.465" height="28.3003" fill="#FFEFE6" />
    <rect x="87.9004" y="140.217" width="28.465" height="28.3003" fill="#FFEFE6" />
    <rect x="59.4355" y="55.3154" width="28.465" height="28.3003" fill="#FFEFE6" />
    <rect x="87.9004" y="27.0127" width="28.465" height="28.3003" fill="#FF9356" />
    <rect x="230.049" y="27.0156" width="28.465" height="28.3003" fill="#FFEFE6" />
    <rect
      x="287.158"
      y="111.916"
      width="28.465"
      height="28.3003"
      transform="rotate(180 287.158 111.916)"
      fill="#FEBE9A"
    />
    <rect
      x="144.83"
      y="140.217"
      width="28.465"
      height="28.3003"
      transform="rotate(180 144.83 140.217)"
      fill="#FEBE9A"
    />
    <rect x="201.762" y="55.3154" width="28.465" height="28.3003" fill="#FEBE9A" />
    <rect x="230.229" y="83.6162" width="28.465" height="28.3003" fill="#FFEFE6" />
    <rect x="87.9004" y="111.917" width="28.465" height="28.3003" fill="#FFEFE6" />
    <rect
      x="287.158"
      y="83.6162"
      width="28.465"
      height="28.3003"
      transform="rotate(180 287.158 83.6162)"
      fill="#FEBE9A"
    />
    <rect
      x="144.83"
      y="111.917"
      width="28.465"
      height="28.3003"
      transform="rotate(180 144.83 111.917)"
      fill="#FEBE9A"
    />
    <rect x="144.83" y="55.3154" width="28.465" height="28.3003" fill="#FEBE9A" />
    <rect x="30.9688" y="168.517" width="28.465" height="28.3003" fill="#FF9356" />
    <rect
      x="287.158"
      y="196.817"
      width="28.465"
      height="28.3003"
      transform="rotate(180 287.158 196.817)"
      fill="#FEBE9A"
    />
    <rect
      x="144.83"
      y="225.116"
      width="28.465"
      height="28.3003"
      transform="rotate(180 144.83 225.116)"
      fill="#FEBE9A"
    />
    <rect
      x="116.365"
      y="83.6152"
      width="28.465"
      height="28.3003"
      transform="rotate(180 116.365 83.6152)"
      fill="#FEBE9A"
    />
    <rect
      x="230.229"
      y="140.216"
      width="28.465"
      height="28.3003"
      transform="rotate(180 230.229 140.216)"
      fill="#FEBE9A"
    />
    <rect
      x="87.9004"
      y="168.517"
      width="28.465"
      height="28.3003"
      transform="rotate(180 87.9004 168.517)"
      fill="#FEBE9A"
    />
    <rect
      x="173.297"
      y="168.517"
      width="28.465"
      height="28.3003"
      transform="rotate(180 173.297 168.517)"
      fill="#FEBE9A"
    />
    <rect x="230.229" y="140.216" width="28.465" height="28.3003" fill="#FEBE9A" />
    <rect x="87.9004" y="168.517" width="28.465" height="28.3003" fill="#FEBE9A" />
    <rect x="173.297" y="83.6152" width="28.465" height="28.3003" fill="#FEBE9A" />
    <rect x="30.9688" y="111.917" width="28.465" height="28.3003" fill="#FEBE9A" />
    <rect
      x="287.158"
      y="111.915"
      width="28.465"
      height="28.3003"
      transform="rotate(180 287.158 111.915)"
      fill="#FEBE9A"
    />
    <rect
      x="144.83"
      y="140.216"
      width="28.465"
      height="28.3003"
      transform="rotate(180 144.83 140.216)"
      fill="#FEBE9A"
    />
    <rect x="201.762" y="55.3154" width="28.465" height="28.3003" fill="#FEBE9A" />
    <rect x="230.229" y="83.6152" width="28.465" height="28.3003" fill="#FEBE9A" />
    <rect x="87.9004" y="111.917" width="28.465" height="28.3003" fill="#FF9356" />
    <rect
      x="287.158"
      y="196.817"
      width="28.465"
      height="28.3003"
      transform="rotate(180 287.158 196.817)"
      fill="#FEBE9A"
    />
    <rect
      x="144.83"
      y="225.116"
      width="28.465"
      height="28.3003"
      transform="rotate(180 144.83 225.116)"
      fill="#FFC933"
    />
    <rect
      x="116.365"
      y="83.6152"
      width="28.465"
      height="28.3003"
      transform="rotate(180 116.365 83.6152)"
      fill="#FFC933"
    />
    <rect
      x="230.229"
      y="140.216"
      width="28.465"
      height="28.3003"
      transform="rotate(180 230.229 140.216)"
      fill="#FFC933"
    />
    <rect
      x="87.9004"
      y="168.517"
      width="28.465"
      height="28.3003"
      transform="rotate(180 87.9004 168.517)"
      fill="#FEBE9A"
    />
    <rect
      x="173.297"
      y="168.517"
      width="28.465"
      height="28.3003"
      transform="rotate(180 173.297 168.517)"
      fill="#FFC933"
    />
    <rect x="230.229" y="140.216" width="28.465" height="28.3003" fill="#FFC933" />
    <rect x="87.9004" y="168.517" width="28.465" height="28.3003" fill="#FFC933" />
    <rect x="173.297" y="83.6152" width="28.465" height="28.3003" fill="#FFC933" />
    <rect x="30.9688" y="111.917" width="28.465" height="28.3003" fill="#FFC933" />
    <rect
      x="287.158"
      y="111.915"
      width="28.465"
      height="28.3003"
      transform="rotate(180 287.158 111.915)"
      fill="#FFC933"
    />
    <rect
      x="144.83"
      y="140.216"
      width="28.465"
      height="28.3003"
      transform="rotate(180 144.83 140.216)"
      fill="#FFC933"
    />
    <rect x="201.762" y="55.3154" width="28.465" height="28.3003" fill="#FF9356" />
    <rect x="201.582" y="140.327" width="28.465" height="28.3003" fill="#FF9356" />
  </svg>
);

// eslint-disable-next-line import/no-default-export
export default SVGMarkets;
