const SVGAutomateDividends = () => (
  <svg
    width="348"
    height="245"
    viewBox="0 0 348 245"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ width: '100%' }}
  >
    <title>A computer screen shot of a network</title>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M192.377 139.779C192.377 152.949 181.7 163.626 168.53 163.626H118.792V161.796H168.53C180.69 161.796 190.547 151.939 190.547 139.779V127.017H192.377V139.779Z"
      fill="#FFC21A"
      role="img"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M168.526 226.118C181.696 226.118 192.373 215.441 192.373 202.271V191.187V180.076H192.375V123.128C192.375 110.969 202.232 101.112 214.391 101.112H272.571V99.2816H214.391C201.221 99.2816 190.544 109.958 190.544 123.128V180.054H190.543V191.187V202.271C190.543 214.43 180.686 224.287 168.526 224.287H54.5586V226.118H168.526Z"
      fill="#FFC21A"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M192.377 68.7264C192.377 80.8859 202.234 90.7431 214.394 90.7431H272.57V92.5732H214.394C201.224 92.5732 190.547 81.8966 190.547 68.7264H192.377ZM168.532 44.8792C181.702 44.8792 192.378 55.5557 192.378 68.726H190.548C190.548 56.5665 180.691 46.7093 168.532 46.7093H65.0078V44.8792H168.532Z"
      fill="#FFC21A"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M83.928 142.626C97.0982 142.626 107.775 131.949 107.775 118.779H107.774C107.775 106.62 117.632 96.7633 129.791 96.7633H272.563V94.9331H129.791C116.62 94.9331 105.944 105.61 105.944 118.78H105.945C105.944 130.939 96.0871 140.796 83.928 140.796H41.2734V142.626H83.928Z"
      fill="#FFC21A"
    />
    <circle r="14.5784" transform="matrix(1 0 0 -1 47.1916 141.596)" fill="#FC5C03" />
    <circle r="14.5784" transform="matrix(1 0 0 -1 128.871 162.71)" fill="#FC5C03" />
    <circle r="14.5784" transform="matrix(1 0 0 -1 76.5198 46.6145)" fill="#FC5C03" />
    <circle r="14.5784" transform="matrix(1 0 0 -1 144.973 46.6145)" fill="#FC5C03" />
    <path
      d="M81.7075 52.002L81.8217 45.962C81.8219 45.8955 81.8088 45.8296 81.7831 45.7682C81.7574 45.7068 81.7197 45.6511 81.6722 45.6045L76.9469 41.1443C76.8598 41.0616 76.7448 41.0144 76.6247 41.0121C76.5046 41.0099 76.3879 41.0526 76.2978 41.1321L71.4072 45.4104C71.3579 45.4551 71.3181 45.5093 71.2902 45.5697C71.2622 45.6301 71.2465 45.6955 71.2442 45.762L71.13 51.802"
      stroke="white"
      strokeWidth="0.961765"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M69.6875 51.7747L83.1498 52.0293"
      stroke="white"
      strokeWidth="0.961765"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M77.8628 51.9293L77.9265 48.5637C77.9289 48.4362 77.8805 48.3129 77.7921 48.221C77.7036 48.1292 77.5823 48.0762 77.4548 48.0738L75.5316 48.0374C75.4041 48.035 75.2808 48.0833 75.1889 48.1718C75.0971 48.2603 75.0441 48.3816 75.0417 48.5091L74.978 51.8747"
      stroke="white"
      strokeWidth="0.961765"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle r="14.5784" transform="matrix(1 0 0 -1 98.8323 225.37)" fill="#FC5C03" />
    <path
      d="M98.9688 218.145L98.6954 232.596"
      stroke="white"
      strokeWidth="0.902353"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M102.775 222.665C102.782 222.3 102.717 221.937 102.584 221.598C102.45 221.258 102.251 220.947 101.998 220.685C101.745 220.422 101.443 220.211 101.108 220.065C100.773 219.919 100.413 219.84 100.049 219.833L97.5473 219.786C96.8102 219.772 96.0978 220.052 95.5667 220.563C95.0357 221.074 94.7295 221.776 94.7156 222.513C94.7016 223.25 94.9811 223.962 95.4924 224.493C96.0037 225.024 96.7051 225.33 97.4422 225.344L100.499 225.402C101.236 225.416 101.938 225.722 102.449 226.253C102.96 226.784 103.24 227.497 103.226 228.234C103.212 228.971 102.906 229.672 102.375 230.184C101.844 230.695 101.131 230.974 100.394 230.96L97.0591 230.897C96.322 230.883 95.6207 230.577 95.1094 230.046C94.598 229.515 94.3186 228.803 94.3325 228.066"
      stroke="white"
      strokeWidth="0.902353"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M126.119 162.25H130.246C130.854 162.25 131.437 162.491 131.867 162.921C132.297 163.351 132.539 163.934 132.539 164.542C132.539 165.151 132.297 165.734 131.867 166.164C131.437 166.594 130.854 166.835 130.246 166.835H126.119V158.123H129.558C130.106 158.123 130.63 158.34 131.017 158.727C131.404 159.114 131.622 159.639 131.622 160.186C131.622 160.734 131.404 161.258 131.017 161.645C130.63 162.032 130.106 162.25 129.558 162.25"
      stroke="white"
      strokeWidth="0.917095"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M125.201 158.123H126.118"
      stroke="white"
      strokeWidth="0.917095"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M125.201 166.835H126.118"
      stroke="white"
      strokeWidth="0.917095"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M127.494 158.123V156.747"
      stroke="white"
      strokeWidth="0.917095"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M129.328 158.123V156.747"
      stroke="white"
      strokeWidth="0.917095"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M127.494 168.211V166.835"
      stroke="white"
      strokeWidth="0.917095"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M129.328 168.211V166.835"
      stroke="white"
      strokeWidth="0.917095"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M138.494 46.1499H151.46"
      stroke="white"
      strokeWidth="0.926118"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M150.534 49.8545V51.2437C150.534 51.3665 150.485 51.4843 150.398 51.5711C150.312 51.6579 150.194 51.7067 150.071 51.7067H148.682C148.559 51.7067 148.441 51.6579 148.354 51.5711C148.268 51.4843 148.219 51.3665 148.219 51.2437V49.8545"
      stroke="white"
      strokeWidth="0.926118"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M141.735 49.8545V51.2437C141.735 51.3665 141.686 51.4843 141.6 51.5711C141.513 51.6579 141.395 51.7067 141.272 51.7067H139.883C139.76 51.7067 139.642 51.6579 139.556 51.5711C139.469 51.4843 139.42 51.3665 139.42 51.2437V49.8545"
      stroke="white"
      strokeWidth="0.926118"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M141.273 48.0022H142.2"
      stroke="white"
      strokeWidth="0.926118"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M147.756 48.0022H148.682"
      stroke="white"
      strokeWidth="0.926118"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M150.533 46.15L148.803 42.2603C148.767 42.178 148.708 42.1079 148.633 42.0586C148.558 42.0092 148.47 41.9828 148.38 41.9824H141.573C141.483 41.9828 141.396 42.0092 141.321 42.0586C141.246 42.1079 141.187 42.178 141.151 42.2603L139.42 46.15V49.8544H150.533V46.15Z"
      stroke="white"
      strokeWidth="0.926118"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M54.1503 137.81L47.5262 144.188L44.8686 141.428L39.9005 146.212"
      stroke="white"
      strokeWidth="0.906278"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M54.0685 142.144L54.1505 137.81L49.8164 137.728"
      stroke="white"
      strokeWidth="0.906278"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle r="14.5784" transform="matrix(1 0 0 -1 108.481 117.094)" fill="#FC5C03" />
    <path
      d="M113.497 112.265L103.468 121.921"
      stroke="white"
      strokeWidth="1.02459"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M104.959 115.385C106.016 115.405 106.889 114.565 106.909 113.508C106.929 112.451 106.088 111.578 105.031 111.558C103.974 111.538 103.101 112.378 103.081 113.435C103.061 114.492 103.902 115.365 104.959 115.385Z"
      stroke="white"
      strokeWidth="1.02459"
      strokeMiterlimit="10"
    />
    <path
      d="M111.931 122.628C112.988 122.648 113.861 121.807 113.881 120.75C113.901 119.693 113.061 118.82 112.004 118.8C110.947 118.78 110.074 119.621 110.054 120.678C110.034 121.735 110.874 122.608 111.931 122.628Z"
      stroke="white"
      strokeWidth="1.02459"
      strokeMiterlimit="10"
    />
    <circle r="14.5784" transform="matrix(1 0 0 -1 137.641 225.371)" fill="#FC5C03" />
    <path
      d="M133.49 225.546L138.749 225.645"
      stroke="white"
      strokeWidth="0.959534"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M141.809 230.701L133.395 230.542C134.022 230.554 134.629 230.316 135.081 229.88C135.533 229.445 135.794 228.848 135.806 228.22L135.915 222.435C135.93 221.668 136.248 220.938 136.801 220.406C137.354 219.874 138.095 219.583 138.862 219.598C139.242 219.603 139.617 219.685 139.966 219.837C140.314 219.989 140.628 220.209 140.891 220.484"
      stroke="white"
      strokeWidth="0.959534"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle r="14.5784" transform="matrix(1 0 0 -1 60.0237 225.083)" fill="#FC5C03" />
    <path
      d="M63.4289 229.384C62.7983 229.991 62.0019 230.397 61.1404 230.551C60.2789 230.706 59.3909 230.601 58.5887 230.251C57.7865 229.901 57.1062 229.321 56.6336 228.584C56.1611 227.847 55.9175 226.987 55.9338 226.112L55.971 224.145C55.9878 223.27 56.2637 222.42 56.7638 221.701C57.2639 220.983 57.9657 220.429 58.7805 220.109C59.5954 219.79 60.4866 219.719 61.3417 219.906C62.1968 220.093 62.9773 220.529 63.5845 221.159"
      stroke="white"
      strokeWidth="0.98365"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M54.4961 224.117L60.3969 224.229"
      stroke="white"
      strokeWidth="0.98365"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M54.459 226.084L59.3764 226.177"
      stroke="white"
      strokeWidth="0.98365"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle r="35.3376" transform="matrix(1 0 0 -1 280.205 96.1741)" fill="#FC5C03" />
    <circle
      cx="280.202"
      cy="96.1727"
      r="16.8937"
      transform="rotate(-180 280.202 96.1727)"
      fill="#FFF1CC"
    />
  </svg>
);

// eslint-disable-next-line import/no-default-export
export default SVGAutomateDividends;
