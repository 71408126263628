const SVGKnowYourPerformance = () => (
  <svg
    width="348"
    height="245"
    viewBox="0 0 348 245"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ width: '100%' }}
    role="img"
  >
    <title>Graph with a pie chart</title>
    <rect x="234.199" y="71.0479" width="34.6674" height="150.953" rx="3.01651" fill="#202DAC" />
    <rect x="141.641" y="71.0479" width="34.6674" height="150.953" rx="3.01651" fill="#7E88E7" />
    <rect x="280.479" y="32.6201" width="34.6674" height="205.072" rx="3.01651" fill="#A9B0EF" />
    <rect x="187.918" y="133.312" width="34.6674" height="104.38" rx="3.01651" fill="#EAEBFB" />
    <rect
      x="58.252"
      y="90.9712"
      width="104.883"
      height="102.163"
      rx="51.0816"
      fill="url(#paint0_linear_1754_13390)"
    />
    <path
      d="M113.413 141.094C88.2427 151.601 37.3751 167.221 32.1972 168.342L90.0403 222.001L113.413 141.094Z"
      fill="url(#paint1_linear_1754_13390)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_1754_13390"
        x1="88.5851"
        y1="167.867"
        x2="171.724"
        y2="112.561"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#A9B0EB" />
        <stop offset="1" stopColor="#D9DDFF" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_1754_13390"
        x1="48.7729"
        y1="141.094"
        x2="102.485"
        y2="160.616"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#6974E3" />
        <stop offset="1" stopColor="#A3ABFB" />
      </linearGradient>
    </defs>
  </svg>
);

// eslint-disable-next-line import/no-default-export
export default SVGKnowYourPerformance;
