const SVGTaxReports = () => (
  <svg
    width="348"
    height="245"
    viewBox="0 0 348 245"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ width: '100%' }}
    role="img"
  >
    <title>A group of papers with different colours</title>
    <rect
      x="32.0098"
      y="32.0361"
      width="107.016"
      height="144.828"
      fill="url(#paint0_linear_1626_11285)"
    />
    <rect
      width="107.016"
      height="144.828"
      transform="matrix(1 0 0 -1 71.2441 195.535)"
      fill="#FFC21A"
    />
    <rect x="110.479" y="72.6162" width="107.016" height="144.828" fill="#FFF8E6" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M250.505 94.7837H202.834V239.818H314.938V140.785L250.505 94.7837Z"
      fill="#FC5C03"
    />
    <path
      d="M250.264 140.895V94.7837L314.939 140.895H250.264Z"
      fill="url(#paint1_linear_1626_11285)"
    />
    <rect x="170.326" y="176.865" width="102.602" height="5.60018" fill="#FFC21A" />
    <rect x="170.326" y="196.932" width="102.602" height="5.60018" fill="#FFC21A" />
    <rect x="170.326" y="186.898" width="102.602" height="5.60018" fill="#FFC21A" />
    <rect x="170.326" y="206.966" width="102.602" height="5.60018" fill="#FFC21A" />
    <rect x="170.326" y="217" width="102.602" height="5.60018" fill="#FFC21A" />
    <defs>
      <linearGradient
        id="paint0_linear_1626_11285"
        x1="32.0098"
        y1="64.7252"
        x2="60.9467"
        y2="197.933"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF823C" />
        <stop offset="1" stopColor="#FEC2A0" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_1626_11285"
        x1="250.264"
        y1="154.005"
        x2="263.84"
        y2="124.739"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF823C" />
        <stop offset="1" stopColor="#FEC2A0" />
      </linearGradient>
    </defs>
  </svg>
);

// eslint-disable-next-line import/no-default-export
export default SVGTaxReports;
