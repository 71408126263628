import styled, { css } from 'styled-components';
import { darken } from 'polished';

import { getReadableColor } from 'styled/helpers/colors';

import {
  buttonBackground,
  buttonBorder,
  buttonTextColor,
} from 'components/Button/buttonAppearances';
import { FlexibleLinkComponent } from 'components/Links';

import type { Highlight } from 'styled/types';

export const imageContainerClassname = 'image_card_container';

export const Container = styled(FlexibleLinkComponent)`
  text-decoration: none;
  display: block;
  width: 100%;
  height: 100%;

  transition:
    text-decoration-offset 300ms,
    text-decoration-thickness 300ms; // NOTE: Not really browser supported…

  /* This gives the button-looking element hover styles when the container is hovered over */
  &:hover .button-div,
  &:focus .button-div,
  &:focus-within .button-div {
    text-underline-offset: 1px;
    text-decoration-thickness: 2px;
  }

  &:hover .button-div,
  &:focus .button-div,
  &:focus-within .button-div {
    background-color: ${buttonBackground.positive.hover.secondary};
    color: ${buttonTextColor.positive.hover.secondary};
    border: ${buttonBorder.positive.hover.secondary};
  }
`;

export const Card = styled.div<{
  background: Highlight;
}>`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  cursor: pointer;
  transition: background-color ease-in-out 0.3s;

  ${({ theme, background }) => {
    const backgroundHex = theme.colors[background];

    return css`
      background-color: ${backgroundHex};
      color: ${getReadableColor({ hex: backgroundHex, requiredContrast: 'AA' })};

      border-radius: ${theme.borderRadius};

      &:hover,
      &:focus,
      &:focus-within {
        background-color: ${darken(0.15, backgroundHex)};
        color: ${getReadableColor({ hex: darken(0.15, backgroundHex), requiredContrast: 'AA' })};
      }
    `;
  }}
`;

export const ImageContainer = styled.div<{ fixedImageHeight: boolean }>`
  width: 100%;

  ${({ fixedImageHeight }) =>
    fixedImageHeight &&
    css`
      /* We're fixing the height to 245px to prevent slight misalignments of the content below 
  the image in cases where the image height is not consistent */
      height: 245px;
    `}
`;
