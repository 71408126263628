import { useMemo } from 'react';

import { ImageCard } from './ImageCard';
import { ImageCardsGrid } from './ImageCardsGrid.styled';

import type { FC } from 'react';
import type { Size } from 'styled/types';
import type { ImageCardProps } from './ImageCard';

export interface ImageCardsProps {
  cards: ImageCardProps[];
  gap?: Size;
  fixedImageHeight?: boolean;
}

export const ImageCards: FC<ImageCardsProps> = ({ cards, gap = 4, fixedImageHeight }) => {
  const columns = cards.length % 3 ? 2 : 3;

  const Cards = useMemo(
    () =>
      cards.map(card => (
        <ImageCard
          key={`image-card-${card.title}`}
          title={card.title}
          content={card.content}
          background={card.background}
          image={card.image}
          to={card.to}
          showButton={card.showButton}
          localize={card.localize}
          buttonText={card.buttonText}
          fixedImageHeight={fixedImageHeight}
        />
      )),
    [cards, fixedImageHeight]
  );

  return (
    <ImageCardsGrid gap={gap} columns={columns}>
      {Cards}
    </ImageCardsGrid>
  );
};
